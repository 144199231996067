import React from 'react';
import MSPLandingPageView1 from 'views/MSPLandingPageView1';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Save on Small Business Cloud Storage Pricing';
const content = {

  hero: {
    title: "",
    emphasized: "Free, Qualified Leads For Your MSP Business",
    subtitle: "Get free, qualified cloud storage leads instantly.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },

  section1_bottom: "",

  providersInfo: {
    default_provider: "msp",
    title: "WindRate has a simple, effective lead generation model.",
    subtitle: "You bid, customers reach out. It's that easy.",
    providers: [
        {
            name: "Managed Service Providers",
            logo: "/images/small-business.svg",
            id: "msp"
        }
    ]
  },

  quoteForm2: {
    quote_provider:"any",
    title: "Instant access to qualified leads",  
    subtitle: "You bid for customer business then customers reach out to you.",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "free-leads-1",   
    
    quote_skin: {
        quoteTitleLeft : "Free leads for your",
        quoteTitleRight : " MSP Business!"
    },
    
    videoSection: {
        title: "Compare and Save on Cloud Storage Costs",
        subtitle: "WindRate is a new way to shop for cloud storage, its the same storage you use now but at incredible discounts",
        callToAction: {
            buttonText: "Get a Quote Now",
            href: "/get-a-quote"
        },

        videoFrame: {
            title: "video",
            src: "https://www.youtube.com/embed/j-tCtk4e6Ys?autoplay=1",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        }
    }
  },

  quoteForm: {
    quote_provider:"any",
    title: "Instant access to qualified leads",  
    subtitle: "You bid for customer business then customers reach out to you.",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "free-leads-1",   
  },

  subText: {
    title: "Same Azure Blob Storage",
    emphasized: "but cheaper",
    subtitle: "Get great pricing on Google Gloud Standard, Coldline, Nearline, Archive storage.<br><br>Compare GCP vs AWS and GCP vs Azure in a single click.<br><br>We will take your requirements and source competitive quotes from leading partners and Managed Service Providers, saving you up to 60% on the same Azure Blob storage you use today. You decide who to deal with and when, your information stays anonymous in the meantime.<br>",
    subtitleEmphasized: "Request a quote today, it’s simple and free!",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }

};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={MSPLandingPageView1}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 