import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
SectionAlternate,
SectionAlternate2,  
Section,
} from 'components/organisms';
import { 
  MSPForm,
  Hero, 
  Providers, 
  Subscription, 
  YouTubeVideoModal,
  SearchResults,
  GoogleInfo,
  ProvidersInfoAny,
  MSPInfo
 } from 'components/windrate';
import { SectionHeader } from 'components/molecules';
import { Typography } from '@material-ui/core';
import { useQueryParam } from 'gatsby-query-params';

import pagedata from "content/pages/index.yaml";
import subscription from "content/global/subscription.yaml";
const chartData = require('content/global/searchdata.json');

const providers = pagedata.providers; 

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
  sectionAlternateEx: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },
  noTopBottomPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
  }, 
  fontWeight900: {
    fontWeight: 900,
  },  
  lessPadding: {
    paddingTop: '3rem !important',
    paddingBottom: '3rem !important',
  }
}));

const Subtext = props => {
  const { data } = props; 
  const classes = useStyles();   
  return (
    <div> 
      <SectionHeader
        title={
          <span>
            {data.title}{' '}
            <Typography component="span" variant="inherit" color="primary" dangerouslySetInnerHTML={{__html:data.emphasized}}>
            </Typography>
          </span>
        }
        subtitle= {
          <span style={{fontSize: "1.3em"}} >
              <span dangerouslySetInnerHTML={{__html:data.subtitle}}>
              </span>
          <br/>
            { data.subtitleEmphasized &&
            <Typography component="span" variant="h6" color="primary"><strong dangerouslySetInnerHTML={{__html:data.subtitleEmphasized}}></strong></Typography>
            }
          </span>
        }
        titleProps={{ className: classes.fontWeight900 }}
      />
	</div>
  );
};


const MSPLandingPageView1 = props => {  
  const location = useQueryParam("location", "any");
  const classes = useStyles();
  const { data } = props;

  const [selectedProvider, setSelectedProvider] = useState(data.quoteForm.quote_provider);

  const providerClicked = (p) =>
  {
      setSelectedProvider(p);
  }

  return (
    <div>
      <YouTubeVideoModal />
      <Hero className={classes.noBottomPadding} data={data.hero} />
      
      <SectionAlternate className={classes.sectionAlternate}>
        <MSPForm data={data.quoteForm2} location={location} show_extended_fields="true"/>    
      </SectionAlternate>

      <Section className={classes.noBottomPadding}>
          <MSPInfo provider={data.providersInfo.default_provider ? data.providersInfo.default_provider : "google"} data={data.providersInfo}/>
          <Typography STYLE="padding-top: 2em" variant="h5" dangerouslySetInnerHTML={{__html:data.section1_bottom}}></Typography>
      </Section>

    { data.subText2 &&
      <Section className={classes.noBottomPadding}>
        <Subtext data={data.subText2} />
      </Section> }

      <Section className={classes.noTopBottomPadding}>
        <MSPForm data={data.quoteForm} location={location}  show_extended_fields="true"/>    
      </Section>

      <SectionAlternate innerNarrowed className={classes.sectionAlternateEx}>
      </SectionAlternate>

    </div>
  );
}

export default MSPLandingPageView1;
